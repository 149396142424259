import React from "react";
import { courses } from "./constants";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function CourseInput({ value, onChange }: Props) {
  return (
    <div className="max-w-sm my-2">
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        Course
      </label>
      <select
        onChange={onChange}
        value={value}
        id="location"
        name="location"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        defaultValue="Canada"
      >
        {courses.map((c) => (
          <option>{c}</option>
        ))}
      </select>
    </div>
  );
}
