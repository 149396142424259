import React from "react";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function NetIdInput({ value, onChange }: Props) {
  return (
    <div className="max-w-sm my-2">
      <label
        htmlFor="email"
        className="text-left block text-sm font-large text-gray-700"
      >
        NetID
      </label>
      <div className="mt-1 max-w-sm">
        <input
          value={value}
          onChange={onChange}
          type="text"
          name="netid"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-md border-gray-300 rounded-md p-1"
          placeholder="ab1234"
        />
      </div>
    </div>
  );
}
