import React, { useState } from "react";
import NetIdInput from "./NetIdInput";
import QRCode from "react-qr-code";
import { BASE_URL, courses } from "./constants";
import CourseInput from "./CourseInput";

export default function QRPage() {
  const [netId, setNetId] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [isQRVisible, setIsQRVisible] = useState(false);
  const [course, setCourse] = useState(courses[0]);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (!/[A-Za-z]+[0-9]+/.test(netId)) {
      setError("Net ID must be one or more letters followed by digits");
    } else {
      setError(undefined);
      setIsQRVisible(true);
    }
  }

  return (
    <>
      <form
        className="max-w-screen-sm p-2 bg-blue-100 m-2 rounded border-blue-300"
        onSubmit={handleSubmit}
      >
        <NetIdInput
          value={netId}
          onChange={(e) => setNetId(e.currentTarget.value)}
        />
        <CourseInput
          value={course}
          onChange={(e) => setCourse(e.currentTarget.value)}
        />
        <button
          type="submit"
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-3"
        >
          Submit
        </button>

        {error && (
          <div className="text-left text-red-500 text-sm"> {error} </div>
        )}
      </form>
      <div className="p-5">
        {isQRVisible && (
          <QRCode
            value={`${BASE_URL}/add?id=${btoa(
              netId.toLowerCase()
            )}&course=${btoa(course)}`}
          />
        )}
      </div>
    </>
  );
}
