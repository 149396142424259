import React from "react";
import QRPage from "./QRPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AddParticipationPage from "./AddParticipationPage";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/add">
          <AddParticipationPage />
        </Route>
        <Route path="/">
          <QRPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
