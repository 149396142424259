import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBYyOR7S02uP5zBR-ubdJWyKwgdEcL9yT8",
  authDomain: "qr-y-edd60.firebaseapp.com",
  projectId: "qr-y-edd60",
  storageBucket: "qr-y-edd60.appspot.com",
  messagingSenderId: "731624192164",
  appId: "1:731624192164:web:98bd815e43f86100ed4e31",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const functions = getFunctions(app);
const addParticipation = httpsCallable(functions, "addParticipation");

export { app, auth, provider, addParticipation };
