import { useEffect, useState } from "react";
import {
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { addParticipation, auth, provider } from "./firebase";
import { unwrapOr, useQuery } from "./utils";

enum PageState {
  Loading,
  NeedSignIn,
  Submitting,
  Done,
  Error,
}

export default function AddParticipationPage() {
  const queryParams = useQuery();
  const [pageState, setPageState] = useState(
    window.location.hash === "#done" ? PageState.Done : PageState.Loading
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const encodedNetId = unwrapOr(queryParams.get("id"), () => {
    setError("Invalid URL");
    setPageState(PageState.Error);
    return "";
  });

  const encodedCourse = unwrapOr(queryParams.get("course"), () => {
    setError("Invalid URL");
    setPageState(PageState.Error);
    return "";
  });

  const netId = atob(encodedNetId);
  const course = atob(encodedCourse);

  useEffect(() => {
    if (pageState === PageState.Submitting) {
      addParticipation({ netId, course })
        .then(() => {
          window.location.hash = "done";
          setPageState(PageState.Done);
        })
        .catch((err) => {
          setPageState(PageState.Error);
          setError(err.message);
        });
    }

    if (pageState === PageState.Loading) {
      setPersistence(auth, browserLocalPersistence)
        .then(() => {
          onAuthStateChanged(auth, (user) => {
            if (!user) {
              setPageState(PageState.NeedSignIn);
            } else {
              setPageState(PageState.Submitting);
            }
          });
        })
        .catch(() => {
          setPageState(PageState.Error);
        });
    }
  }, [pageState, netId, course]);

  let pageContent = <div> This shouldn't be reachable </div>;

  if (pageState === PageState.Error) {
    pageContent = (
      <div className="text-left text-red-500 text-sm bg-red-100 my-2 rounded border-red-300 max-w-xs p-2 border">
        {error ?? "An unknown error occurred. Refresh and try again?"}
      </div>
    );
  }

  if (pageState === PageState.Loading) {
    pageContent = <div className="p-2"> Loading... </div>;
  }

  if (pageState === PageState.NeedSignIn) {
    pageContent = (
      <div>
        <button
          onClick={() => {
            signInWithPopup(auth, provider)
              .then(() => {
                setPageState(PageState.Submitting);
              })
              .catch(() => {
                setError("Cannot sign in");
              });
          }}
          type="button"
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Sign In
        </button>
      </div>
    );
  }

  if (pageState === PageState.Submitting) {
    pageContent = <div>Adding {netId}...</div>;
  }

  if (pageState === PageState.Done) {
    pageContent = <div> Done! You can close this window </div>;
  }

  return (
    <div className="p-5">
      {pageState !== PageState.NeedSignIn && (
        <button
          onClick={() => {
            auth
              .signOut()
              .then(() => {
                setPageState(PageState.NeedSignIn);
              })
              .catch((err) => {
                setPageState(PageState.Error);
              });
          }}
          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Sign Out
        </button>
      )}
      {pageContent}
    </div>
  );
}
